<template lang="pug">
  .claim-modal_panel
    .claim-modal_item(title="Время создания претензии")
      p.item-title Дата/Время:
      .item-content
        span {{ divideDate(claimData.created_at).dateDay }}
        time {{ divideDate(claimData.created_at).time }}
    .claim-modal_item(title="ID претензии")
      p.item-title ID:
      .item-content
        span {{ claimData.id }}
    .claim-modal_item(title="Перейти на страницу сделки")
      p.item-title Номер сделки:
      .item-content
        span.item-link(@click="goToDeal") {{ claimData.deal.id }}
    .claim-modal_item.claim-status(title="Статус претензии" :class="claimData.status.title.toLowerCase()")
      p.item-title Статус сделки:
        .item-content {{ getStatus(claimData.status.title) }}
    .claim-modal_item(title="Сумма сделки")
      p.item-title Сумма сделки:
      .item-content
        span {{ claimData.fiat_amount }} {{ claimData.deal.ad.currency.code }} / {{ claimData.crypto_amount }} {{ claimData.deal.ad.crypto_currency.code .toUpperCase()  }}
    .claim-modal_item(title="Комментарий претензии")
      p.item-title.item-text Комментарий претензии:
      .item-content
        span {{ claimData.claim }}
    .claim-modal_item(v-if="claimData.images.length > 0" title="Изображения")
      p.item-title.item-text Изображения:
      .item-content
        div(v-for="(image, index) in claimData.images" :key="index")
          a(:href="image" target="_blank") Изображение {{ index + 1 }}
    .claim-modal_buttons(v-if="claimData.status.title === 'Opened'")
      .claim-modal__to-deal(v-if="claimData.author.id !== getUserId")
        button.btn.cancel-purchase.mx-2.my-0(@click="cancelClaim" :disabled="isLoading") Отменить
        button.btn.btn-success.btn-finex(@click="showCustomSumModal" :disabled="isLoading") Принять
        .twofactor-popup__container(v-if="isShowCustomSumModal")
          .twofactor-popup-main__field
            .twofactor-popup-close__field(@click="showCustomSumModal")
            .claim__block
              .twofactor-popup-close__btn(@click="showCustomSumModal")
                i(class="el-icon-close")
              .twofactor-popup__title Сумма претензии
              .twofactor-popup__description Выберите сумму для закрытия претензии
              button.btn.btn-success.w-100.claim__confirm-button(@click="confirmClaim()") Закрыть с текущей суммой
              button.btn.btn__ghost_light.w-100.claim__confirm-button(@click="confirmClaimCustomAmount()") Закрыть с другой суммой
      .claim-modal__to-deal(v-else)
        button.btn.cancel-purchase.mx-2.my-0(@click="removeClaim" :disabled="isLoading") Отменить
    safety(
      :show-popup="showSafetyPopup"
      @close="closeSafety"
      @code="getCode"
      :error="safety_error"
      :type="safety_type"
      @resend="resendSafety"
    )
</template>

<script>

import utils from '@/components/mixins/utils';
import {mapActions, mapGetters} from "vuex";
import {APPLY_CLAIM, DELETE_CLAIM, GET_USER_SEND_REQUEST, REJECT_CLAIM} from "@/store/actions/claims";
import errors from '@/components/mixins/errors';
import security from '@/components/mixins/security';
import Safety from "@/components/Common/Safety";

export default {
  name: "ClaimModalItem",
  components: {Safety},
  mixins: [
    utils,
    errors,
    security,
  ],
  props: {
    claimData: {
      type: Object,
      default: () => {
      }
    },
  },
  data() {
    return {
      google2fa_secret: '',
      showSafetyPopup: false,
      otp_secret: '',
      safety_error: '',
      safety_type: 'twoFa',
      isShowCustomSumModal: false,
      custom_amount: '',
    }
  },
  computed: {
    ...mapGetters([
      'loading',
      'getUserId',
    ]),
    isLoading() {
      return this.loading(DELETE_CLAIM) === 'loading' || this.loading(APPLY_CLAIM) === 'loading'
          || this.loading(REJECT_CLAIM) === 'loading' || this.loading('GET_INCOMING_CLAIMS') === 'loading'
          || this.loading('GET_OUTGOING_CLAIMS') === 'loading';
    },
  },
  methods: {
    ...mapActions({
      rejectClaim: REJECT_CLAIM,
      applyClaim: APPLY_CLAIM,
      deleteClaim: DELETE_CLAIM
    }),
    comment(val) {
      if (val.length > 25) {
        return val.slice(0, 25) + '...'
      } else return val
    },
    showCustomSumModal() {
      this.isShowCustomSumModal = !this.isShowCustomSumModal;
    },
    confirmClaimCustomAmount() {
      this.$prompt('Введите сумму закрытия претензии', 'Другая сумма', {
        confirmButtonText: 'Подтвертить сумму',
        cancelButtonText: 'Отмена',
      }).then(res => {
        this.custom_amount = res.value;
        this.sendApplyClaimRequest();
      })
    },
    confirmClaim() {
      this.$confirm('Вы уверены, что хотите принять? Требуемая сумма будет переведена на аккаунт автора претензии.', `Внимание`, {
        confirmButtonText: 'Да',
        cancelButtonText: 'Нет',
        type: 'warning'
      }).then(() => {
        this.sendApplyClaimRequest();
      })
    },
    sendApplyClaimRequest(with_otp = false, with_2fa = false) {
      this.$store.commit(GET_USER_SEND_REQUEST, true)
      let payload = {
        id: this.claimData.id
      }
      if (this.custom_amount) {
        payload.fiat_amount_custom = this.custom_amount;
      }
      if (with_2fa) {
        payload.google2fa_secret = this.google2fa_secret;
      }
      if (with_otp) {
        payload.otp = this.otp_secret;
      }
      this.safety_error = ''
      this.applyClaim(payload).then(() => {
        this.$emit('closeModal')
        this.closeSafety();
      }).catch((error) => {
        const safety_errors = this.haveSecurityError(error);
        if (safety_errors.have) {
          if (safety_errors.need_open && this.showSafetyPopup === false) {
            this.safety_type = safety_errors.type;
            this.showSafetyPopup = true;
          } else {
            this.safety_error = safety_errors.message;
          }
        } else if (error && error.errors) {
          this.$message.error({
            dangerouslyUseHTMLString: true,
            message: this.formatErrors(error.errors),
          });
        } else {
          this.$message.error(error.message);
        }
      }).finally(() => {
        this.$store.commit(GET_USER_SEND_REQUEST, false)
      })
    },
    cancelClaim() {
      this.$confirm('Вы уверены, что хотите отменить претензию?', `Внимание`, {
        confirmButtonText: 'Да',
        cancelButtonText: 'Нет',
        type: 'warning'
      }).then(() => {
        let id = this.claimData.id;
        this.rejectClaim({id}).then(() => {
          this.$emit('closeModal');
        }).catch(() => {
          this.$message.error('Не удалось отменить претензию.');
        })
      })
    },
    removeClaim() {
      this.$confirm('Вы уверены, что хотите отменить претензию?', `Внимание`, {
        confirmButtonText: 'Да',
        cancelButtonText: 'Нет',
        type: 'warning'
      }).then(() => {
        let id = this.claimData.id;
        this.deleteClaim({id}).then(() => {
          this.$emit('closeModal');
        }).catch(() => {
          this.$message.error('Не удалось отменить претензию.');
        })
      })
    },
    goToDeal() {
      this.$router.push({name: 'Deal', params: {id: this.claimData.deal.id}});
    },
    getStatus(name) {
      const status = this.$store.state.claimStatuses.find(status => status.name === name);
      return status?.title;
    },
    closeSafety() {
      this.showSafetyPopup = false;
      this.google2fa_secret = '';
      this.otp_secret = '';
    },
    getCode({ code, type }) {
      if (type === 'otp') {
        this.otp_secret = code;
        this.sendApplyClaimRequest(true)
      } else {
        this.google2fa_secret = code;
        this.sendApplyClaimRequest(false, true)
      }
    },
    resendSafety() {
      this.safety_error = '';
      this.sendApplyClaimRequest(false)
    },
  },
}
</script>

<style lang="scss" scoped>
.claim-modal_panel {
  display: flex;
  flex-direction: column;
  cursor: default;

  .claim-status {
    font-weight: bold;
    align-items: flex-start;

    .rejected, .deleted {
      color: red;
    }

    .applied {
      color: #6cce51;
    }
  }

  .claim-modal_item {
    display: flex;
    padding-top: 5px;
    flex-wrap: wrap;

    .item-title {
      font-weight: bold;
      padding-right: 5px;
    }

    .item-content {
      font-style: oblique;

      .item-link:hover {
        text-decoration: underline;
        cursor: pointer;
        color: #0D9F45;
      }
    }
  }

  .claim-modal_buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 10px;
    .claim-modal__to-deal {
      .claim__block {
        position: absolute;
        width: 300px;
        padding: 20px;
        background: white;
        border-radius: 8px;
        z-index: 2003;
        text-align: center;
        font-size: 12px;
      }
      .claim__confirm-button {
        margin-top: 10px;
      }
    }
  }
  .cancel-purchase {
    width: auto;
  }
}
</style>
