import { NEED_TWO_FA, NEED_TWO_FA_SECOND, WRONG_TWO_FA } from "@/store/actions/security";

export default {
  methods: {
    /**
     *
     * @param error
     * @returns {{need_open: boolean, have: boolean, message: null, type: string}|{need_open: boolean, have: boolean, message: null, type: null}|{need_open: boolean, have: boolean, message: string, type: string}}
     */
    haveSecurityError(error) {
      if (error && error.errors && error.errors.google2fa_secret) {
        if (error.errors.google2fa_secret.find(err => err === NEED_TWO_FA || err === NEED_TWO_FA_SECOND)) {
          return { have: true, need_open: true, message: NEED_TWO_FA, type: 'twoFa' }
        } else if (error.errors.google2fa_secret.find(err => err === WRONG_TWO_FA)) {
          return { have: true, need_open: false, message: WRONG_TWO_FA, type: 'twoFa' }
        }
      } else if (error && error.status === 406 || error.status === 422 && (error.errors && error.errors.otp)) {
        let message = 'Не верный одноразовый код'; // TODO сказать чтоб бэк присылал нормальный ответ
        if(error.message && error.message === 'OTP is required') {
          message = null;
        }
        return { have: true, need_open: true, message, type: 'otp' }
      }
      return { have: false, need_open: false, message: null, type: null }
    }
  }
}
