<template lang="pug">
  .operation-block.operation-block__claim
    .operation-block__arrow
    .operation-block__data
      span ID: {{ claim.id }}
    .operation-block__data
      span {{ divideDate(claim.created_at).dateDay }}
      time {{ divideDate(claim.created_at).time }}
    .operation-block__purchase
      span(class='claimDealId' @click="dialogDealVisible = true") Сделка №: {{ claim.deal.id }}
        i(@click="dialogDealVisible = true")
      el-dialog(
        :visible.sync="dialogDealVisible"
        :title='`Информация о сделке №:${claim.deal.id}`'
        width="500px"
      )
        claim-deal-details(:id="claim.deal.id")
    .operation-block__pay
      span.comment(@click="dialogVisible = true") {{ comment(claim.claim)}}&nbsp;&nbsp;
      i(class='el-icon-view eye-icon' @click="dialogVisible = true")
      el-dialog(
        :visible.sync="dialogVisible"
        title="Комментарий"
        width="30%"
      )
        span {{claim.claim}}
    .operation-block__pay.operation-block__claim-image(v-if="claim.images.length > 0")
      div.operation-block__claim-open(@click="dialogImagesVisible = true")
        i(class='el-icon-view el-icon-camera' @click="dialogImagesVisible = true")
      el-dialog(
        :visible.sync="dialogImagesVisible"
        title="Изображения"
        width="30%"
      )
        div(v-for="(image, index) in claim.images" :key="index")
          a(:href="image" target="_blank") Изображение {{ index + 1 }}
    .operation-block__w36(v-else)
    .operation-block__given.mr-5.claim-status
      div(v-if="claim.status.title === 'Pending'" :class="claim.status.title.toLowerCase()")
        template(v-if="claim.author.id !== getUserId")
          el-tooltip(
            class="item"
            effect="dark"
            content="Средства по этой претензии будут автоматически списаны со счета после пополнения"
            placement="top"
          )
            .pending-block__info {{ getStatus(claim.status.title) }}
        template(v-else)
          el-tooltip(
            class="item"
            effect="dark"
            content="Биткоины по данной претензии будут начислены после того, как у пользователя на аккаунте будет необходимая сумма"
            placement="top"
          )
            .pending-block__info {{ getStatus(claim.status.title) }}
      p(v-else title="Статус" :class="claim.status.title.toLowerCase()") {{ getStatus(claim.status.title) }}
    .operation-block__given.mr-5
      p(title="Сумма претензии") {{ claim.fiat_amount }} {{ claim.deal.ad.currency.code }} / {{ claim.crypto_amount }} {{ claim.deal.ad.crypto_currency.code.toUpperCase() }}
    .claim-buttons(v-if="claim.status.title === 'Opened'")
      .operation-block__to-deal(v-if="claim.author.id !== getUserId")
        button.btn.btn__ghost_light.mx-2.my-0(@click="cancelClaim" :disabled="isLoading") Отменить
        button.btn.btn__main(@click="showCustomSumModal" :disabled="isLoading") Принять
        .twofactor-popup__container(v-if="isShowCustomSumModal")
          .twofactor-popup-main__field
            .twofactor-popup-close__field(@click="showCustomSumModal")
            .claim__block
              .twofactor-popup-close__btn(@click="showCustomSumModal")
                i(class="el-icon-close")
              .twofactor-popup__title Сумма претензии
              .twofactor-popup__description Выберите сумму для закрытия претензии
              button.btn.btn-success.w-100.claim__confirm-button(class="cancel" type="cancel" @click="confirmClaim()") Закрыть с текущей суммой
              button.btn.btn__ghost_light.w-100.claim__confirm-button(type="success" @click="confirmClaimCustomAmount()") Закрыть с другой суммой
      .operation-block__to-deal(v-else)
        button.btn.cancel-purchase.mx-2.my-0(@click="removeClaim" :disabled="isLoading") Отменить
    .pending__container(v-else-if="claim.status.title === 'Pending'")
      .pending__empty_other(v-if="claim.author.id !== getUserId")
      .pending__empty(v-else)
    safety(
      :show-popup="showSafetyPopup"
      @close="closeSafety"
      @code="getCode"
      :error="safety_error"
      :type="safety_type"
      @resend="resendSafety"
    )

</template>
<script>
  import {mapActions, mapGetters} from 'vuex';
  import utils from '@/components/mixins/utils';
  import {APPLY_CLAIM, DELETE_CLAIM, GET_USER_SEND_REQUEST, REJECT_CLAIM} from "@/store/actions/claims";
  import ClaimDealDetails from "@/components/Claims/ClaimDealDetails";
  import errors from '@/components/mixins/errors';
  import security from '@/components/mixins/security';
  import Safety from "@/components/Common/Safety";

  export default {
    components: {ClaimDealDetails, Safety},
    mixins: [
      utils,
      errors,
      security,
    ],
    props: {
      claim: {
        type: Object,
        default() {
            return {};
        },
      },
    },
    data() {
      return {
        dialogVisible: false,
        dialogDealVisible: false,
        dialogImagesVisible: false,
        google2fa_secret: '',
        showSafetyPopup: false,
        otp_secret: '',
        safety_error: '',
        safety_type: 'twoFa',
        isShowCustomSumModal: false,
        custom_amount: '',
      }
    },
    computed: {
      ...mapGetters([
        'loading',
        'getUserId',
        'getProfile',
      ]),
      isLoading() {
        return this.loading(DELETE_CLAIM) === 'loading' || this.loading(APPLY_CLAIM) === 'loading'
            || this.loading(REJECT_CLAIM) === 'loading' || this.loading('GET_INCOMING_CLAIMS') === 'loading'
            || this.loading('GET_OUTGOING_CLAIMS') === 'loading';
      },
      isProTrader() {
        return this.getProfile.pro_trader;
      },
    },
    methods: {
      ...mapActions({
        rejectClaim: REJECT_CLAIM,
        applyClaim: APPLY_CLAIM,
        deleteClaim: DELETE_CLAIM
      }),
      comment(val) {
        if (val.length > 25) {
          return val.slice(0, 25) + '...'
        } else return val
      },
      showCustomSumModal() {
        this.isShowCustomSumModal = !this.isShowCustomSumModal;
      },
      confirmClaimCustomAmount() {
        this.$prompt('Введите сумму закрытия претензии', 'Другая сумма', {
          confirmButtonText: 'Подтвердить сумму',
          cancelButtonText: 'Отмена',
        }).then(res => {
          this.custom_amount = res.value;
          this.sendApplyClaimRequest();
        })
      },
      confirmClaim(){
        this.$confirm('Вы уверены, что хотите принять? Требуемая сумма будет переведена на аккаунт автора претензии.', `Внимание`, {
          confirmButtonText: 'Да',
          cancelButtonText: 'Нет',
          type: 'warning'
        }).then(() => {
          this.sendApplyClaimRequest();
        })
      },
      sendApplyClaimRequest(with_otp = false, with_2fa = false) {
        this.$store.commit(GET_USER_SEND_REQUEST, true)
        let payload = {
          id: this.claim.id
        }
        if (this.custom_amount) {
          payload.fiat_amount_custom = this.custom_amount;
        }
        if(with_2fa) {
          payload.google2fa_secret = this.google2fa_secret;
        }
        if (with_otp) {
          payload.otp = this.otp_secret;
        }
        this.safety_error = ''
        this.applyClaim(payload).then(() => {
          this.$emit('updateList')
          this.closeSafety();
        }).catch((error) => {
          const safety_errors = this.haveSecurityError(error);
          if (safety_errors.have) {
            if (safety_errors.need_open && this.showSafetyPopup === false) {
              this.safety_type = safety_errors.type;
              this.showSafetyPopup = true;
            } else {
              this.safety_error = safety_errors.message;
            }
          } else if (error && error.errors) {
            this.$message.error({
              dangerouslyUseHTMLString: true,
              message: this.formatErrors(error.errors),
            });
          } else {
            this.$message.error(error.message);
          }
        }).finally(() => {
          this.$store.commit(GET_USER_SEND_REQUEST, false)
        })
      },
      cancelClaim(){
        this.$confirm('Вы уверены, что хотите отменить претензию?', `Внимание`, {
          confirmButtonText: 'Да',
          cancelButtonText: 'Нет',
          type: 'warning'
        }).then(() => {
          this.$store.commit(GET_USER_SEND_REQUEST, true)
          let id = this.claim.id;
          this.rejectClaim({id}).then(() => {
            this.$emit('updateList')
          }).catch(() => {
            this.$message.error('Не удалось отменить претензию.');
          }).finally(() => {
            this.$store.commit(GET_USER_SEND_REQUEST, false)
          })
        })
      },
      removeClaim() {
        this.$confirm('Вы уверены, что хотите отменить претензию?', `Внимание`, {
          confirmButtonText: 'Да',
          cancelButtonText: 'Нет',
          type: 'warning'
        }).then(() => {
          this.$store.commit(GET_USER_SEND_REQUEST, true)
          let id = this.claim.id;
          this.deleteClaim({id}).then(() => {
            this.$emit('updateList')
          }).catch((error) => {
            let message = 'Не удалось отменить претензию.';
            if(error.errors && error.errors.length > 0) {
              message = this.errors[0];
            }
            this.$message.error(message);
          }).finally(() => {
            this.$store.commit(GET_USER_SEND_REQUEST, false)
          })
        })
      },
      getStatus(name) {
        const status = this.$store.state.claimStatuses.find(status => status.name === name);
        return status?.title;
      },
      closeSafety() {
        this.showSafetyPopup = false;
        this.google2fa_secret = '';
        this.otp_secret = '';
      },
      getCode({ code, type }) {
        if (type === 'otp') {
          this.otp_secret = code;
          this.sendApplyClaimRequest(true)
        } else {
          this.google2fa_secret = code;
          this.sendApplyClaimRequest(false, true)
        }
      },
      resendSafety() {
        this.safety_error = '';
        this.sendApplyClaimRequest()
      },
    },
  }
</script>
<style lang="scss">
  .claim-buttons {
    .operation-block__to-deal {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 10px;
      .claim__block {
        position: absolute;
        width: 300px;
        padding: 20px;
        background: white;
        border-radius: 8px;
        z-index: 2003;
        text-align: center;
        font-size: 12px;
      }
      .claim__confirm-button {
        margin-top: 10px;
      }
    }
  }

  .operation-block__purchase {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border-radius: 10px;
    min-width: 180px;
    max-width: 180px;
    margin-left: 0;

    p {
      display: flex;
      .user-link {
        color: $blue800;
      }
    }
    &:hover .eye-icon{
      color: $blue800;
    }
    &:hover {
      background-color: #f2f2f2;
      cursor: pointer;
    }

    .btn-claim {
      margin-top: 20px;
    }
  }

  .operation-block__pay {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    width: 200px;
    border-radius: 10px;
    .comment {
      text-align: center;
      width: 16ch;
      white-space: nowrap;
      overflow: hidden;
      -o-text-overflow: ellipsis;
      text-overflow: ellipsis;
    }
    &:hover .el-icon-camera,
    &:hover .eye-icon{
      color: $blue600;
    }
    &:hover {
      background-color: #f2f2f2;
      cursor: pointer;
    }
  }
  .claim-status {
    font-weight: bold;

    .rejected, .deleted {
      color: red;
    }
    .applied {
      color: $blue600;
    }
  }
  .eye-icon {
    margin-top: 3px;
  }
  .operation-block.operation-block__claim button.btn {
    min-width: 100px;
  }
  .operation-block button.el-dialog__headerbtn{
    min-width: auto;
  }
  .claimId:hover{
    cursor: pointer;
    text-decoration: underline;
  }
  .claimDealId:hover{
    cursor: pointer;
  }
  .pending-block__info{
    cursor: pointer;
    text-align: center;
  }
  .pending__empty_other{
    min-width: 217px;
  }
  .pending__empty{
    min-width: 116px;
  }
  .operation-block__w36{
    width: 36px;
  }
  .operation-block__claim-image {
    width: 36px;
    padding: 0;
  }
  .operation-block__claim-open {
    padding: 10px;
  }
</style>
