<template lang="pug">
  .claim-modal_panel
    template(v-if="deal.id")
      .claim-modal_item(title="Время открытия сделки")
        p.item-title Дата/Время:
        .item-content
          span {{ divideDate(deal.created_at).dateDay }} #[time {{ divideDate(deal.created_at).time }}]
      .claim-modal_item(title="Цена")
        p.item-title Цена:
        .item-content
          span {{ deal.price }} {{ deal.ad.currency.code }}
      .claim-modal_item(v-if="deal.requisites" title="Реквизиты")
        p.item-title Реквизиты:
        .item-content
          span {{ deal.requisites.seller_info }}
      .claim-modal_item(title="Сумма сделки")
        p.item-title Сумма сделки:
        .item-content
          span {{ deal.fiat_amount }} {{ deal.ad.currency.code }} / {{ deal.crypto_amount }} {{ deal.ad.crypto_currency.code.toUpperCase() }}
      .claim-modal_item(title="Перейти в профиль")
        p.item-title.item-text Сделка с <!-- Save it for space -->
        router-link.item-content(:to="{name: 'Profile', params: {id: getOpponentId}}")
          span.user-link {{ getOpponentLogin }}
      .claim-modal_item(title="Условия сделки")
        p.item-title Условия сделки:
        .item-content
          span.item-status(v-if="deal.conditions === null") Нет условий сделки
          span.item-status(v-else) {{ deal.conditions }}
      button.btn.btn-success.btn-finex.btn-claim(@click="goToDeal") Перейти к сделке
</template>

<script>
import utils from '@/components/mixins/utils';
import {DEAL, DEAL_LISTEN} from "@/store/actions/deal";
import {mapGetters} from "vuex";

export default {
  name: "ClaimDealDetails",
  mixins: [
    utils
  ],
  props: {
    id: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      deal: {}
    }
  },
  computed: {
    ...mapGetters([
      'getProfile',
    ]),
    getOpponentId() {
      return this.deal.author.id === this.getProfile.id ? this.deal.offer.id : this.deal.author.id;
    },
    getOpponentLogin() {
      if (this.deal.author.login === this.getProfile.login) {
        return this.deal.offer.login
      } else if (this.deal.offer.login !== this.getProfile.login && this.deal.author.login !== this.getProfile.login) {
        return this.deal.offer.login
      } else {
        return this.deal.author.login
      }
    }
  },
  created() {
    this.$store.dispatch(DEAL, { id: this.id }).then((res) => {
      this.deal = res.data
    });
  },
  methods: {
    goToDeal() {
      this.$router.push({ name: 'Deal', params: { id: this.deal.id } });
    }
  }
}
</script>
