<template lang="pug">
  .twofactor-popup__container(v-if="show")
    .twofactor-popup-main__field
      .twofactor-popup-close__field(@click="closePopup")
      .twofactor-popup-main__block(:class="{ 'otp-popup-main__block': isOtp }")
        .twofactor-popup-close__btn(@click="closePopup")
          i(class="el-icon-close")
        .twofactor-popup__title {{ getMessage.popup_title }}
        .twofactor-popup__description {{ getMessage.popup_description }}
        el-input.twofactor-popup-2fa__value(
          @input="clearErrors"
          v-model="code"
        )
        .twofactor-popup-2fa-error__container
          .twofactor-error(v-if="isError") {{ errorText }}
        el-button.twofactor-popup-2fa__confirm(
          type="success"
          @click="validateAndEmit"
          :disabled="disabled"
        ) Подтвердить
        br(v-if="isOtp")
        el-button.mt-2(
          :disabled="!can_resend"
          v-if="isOtp"
          @click="resend"
        ) Отправить код повторно
</template>

<script>
export default {
  name: 'Safety', // Универсальный компонент для обработки 2fa или otp
  props: {
    showPopup: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'twoFa',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: '',
    }
  },
  data() {
    return {
      show: false,
      code: '',
      isError: false,
      errorText: '',
      can_resend: true,
      messages: {
        twoFa: {
          popup_title: '2FA-код',
          popup_description: 'Введите 2FA-код из вашего Google-аутентификатора',
          empty_code: 'Заполните поле 2FA',
          too_short: 'Код слишком короткий',
          too_long: 'Код слишком длинный',
        },
        otp: {
          popup_title: 'Одноразовый код',
          popup_description: 'Введите одноразовый код, высланный вам на почту, чтобы подтвердить вывод.',
          empty_code: 'Введите одноразовый код высланный вам на почту',
          too_short: 'Код слишком короткий',
          too_long: 'Код слишком длинный',
        },
      }
    }
  },
  computed: {
    getMessage() {
      return this.messages[this.type];
    },
    isOtp() {
      return this.type === 'otp'
    }
  },
  watch: {
    showPopup(value) {
      if (!value) {
        this.code = ''
      }
      this.show = value
    },
    error(value) {
      if(value || value === '') {
        this.errorText = value;
        this.isError = true;
      }
    }
  },
  mounted() {
    this.show = this.showPopup;
  },
  methods: {
    closePopup() {
      this.code = '';
      this.show = false;
      this.clearErrors();
      this.$emit('close')
    },
    clearErrors() {
      this.errorText = '';
      this.isError = false;
    },
    validateAndEmit() {
      this.can_resend = true;
      if (this.code === '') {
        this.errorText = this.getMessage.empty_code;
        this.isError = true;
        return;
      } else if (this.code.length < 6) {
        this.errorText = this.getMessage.too_short;
        this.isError = true;
        return;
      } else if (this.code.length > 6) {
        this.errorText = this.getMessage.too_long;
        this.isError = true;
        return;
      }
      this.$emit('code', { code: this.code, type: this.type });
    },
    resend() {
      this.can_resend = false;
      this.$emit('resend')
    }
  }
}
</script>

