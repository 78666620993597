<template lang="pug">
  .main-content.layout-main__centering.container.pb-5(
    element-loading-text='Загрузка данных...',
  )
    ClaimsContainer
</template>
<script>
  import ClaimsContainer from "../components/Claims/ClaimsContainer";

  export default {
    components: {
      ClaimsContainer,
    },
    data() {
      return {}
    }
  }
</script>
<style scoped>
</style>
