export default {
  methods: {
    /**
     * Собирает все ошибки в одну строку с переносами
     * @param errors
     * @returns {string}
     */
    formatErrors(errors) {
      let message = '';
      for (const error_type in errors) {
        for (const errorItem of errors[error_type]) {
          message += `${errorItem}<br>`
        }
      }
      return message;
    }
  }
}
