<template lang="pug">
  section.content-tab
    section.page-info.page-info_purse
      .page-info__container
        .page-info__active
          el-switch.switch-block(
            v-model="isClosed"
            active-color="#13ce66"
            :active-value="0"
            :inactive-value="1"
            active-text="Активные"
            inactive-text="Завершенные"
            :disabled="isLoading"
            @change="updateList"
          )
          input(
            placeholder="Введите ID сделки"
            v-model="dealIDFilter"
            @input="debouncedFilterClaims"
          )
        hr
        .page-deals-no-deals(v-if="(getOutgoingClaimsList.length === 0 && currentTab === 'outgoing') || (getIncomingClaimsList.length === 0 && currentTab === 'incoming')") Нет {{ this.isClosed ? 'завершенных' : 'активных' }} претензий
        div(v-else)
          Claim(v-for="claim in claimsList", :claim="claim", :key="`claim-${claim.id}`" @updateList="updateList")
    .deals-pagination
      el-pagination(
        v-if='hasPagination',
        :total='getClaimPagination.total',
        :page-size='parseInt(getClaimPagination.per_page)',
        :current-page='getClaimPagination.current_page',
        :disabled='isLoading'
        background,
        layout='prev, pager, next',
        @current-change='changeCurrent',
      )
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Claim from './Claim';
import {
  CHANGE_CLAIMS_SWITCH,
  GET_INCOMING_CLAIMS,
  GET_OUTGOING_CLAIMS,
} from "@/store/actions/claims";

export default {
  components: {
    Claim
  },
  props: {
    currentTab: {
      type: String,
      default: 'outgoing'
    },
    perPage: {
      type: Number,
      default: 15
    }
  },
  data() {
    return {
      dealIDFilter: this.$route.query.deal_id || '',
      isClosed: Boolean(Number(this.$route.query.closed)) || this.$route.query.closed === null ? 1 : 0,
      per_page: 15,
    };
  },
  computed: {
    ...mapGetters([
      'getOutgoingClaimsList',
      'getIncomingClaimsList',
      'getClaimPagination',
      'loading'
    ]),
    isLoading() {
      return this.loading(GET_INCOMING_CLAIMS) === 'loading' || this.loading(GET_OUTGOING_CLAIMS) === 'loading';
    },
    hasPagination() {
      return !!this.getClaimPagination && this.getClaimPagination.total_pages > 1;
    },
    filteredIncomingClaims() {
      if (this.isClosed === 0) {
        return this.getIncomingClaimsList.filter(i => i.status.id === 1 || i.status.id === 7)
      }
      return this.getIncomingClaimsList.filter(i => i.status.id !== 1)
    },
    filteredOutgoingClaims() {
      if (this.isClosed === 0) {
        return this.getOutgoingClaimsList.filter(i => i.status.id === 1 || i.status.id === 7)
      }
      return this.getOutgoingClaimsList.filter(i => i.status.id !== 1)
    },
    claimsList() {
      return this.currentTab === 'incoming' ? this.filteredIncomingClaims : this.filteredOutgoingClaims
    }
  },
  watch: {
    currentTab() {
      this.isClosed = 0;
      this.updateList();
      this.resetQueryFilters();
    },
    dealIDFilter: 'resetQueryFilters',
    isClosed: 'resetQueryFilters',
  },
  created() {
    this.updateList()
    this.debouncedFilterClaims = _.debounce(this.filterClaims, 1000);
  },
  methods: {
    ...mapActions({
      getOutgoingClaims: GET_OUTGOING_CLAIMS,
      getIncomingClaims: GET_INCOMING_CLAIMS,
    }),
    changeCurrent(page) {
      this.updateList(page)
    },
    filterClaims() {
      this.updateList()
    },
    async updateList(page = 1) {
      this.$store.commit(CHANGE_CLAIMS_SWITCH, this.isClosed)
      if (this.currentTab === 'outgoing') {
        await this.getOutgoingClaims({closed: this.isClosed, page, per_page: this.per_page, deal_id: this.dealIDFilter})
      } else {
        await this.getIncomingClaims({closed: this.isClosed, page, per_page: this.per_page, deal_id: this.dealIDFilter})
      }
    },
    async resetQueryFilters() {
      try {
        const query = { ...this.$route.query };

        delete query.deal_id;
        delete query.incoming;
        delete query.closed;

        await this.$router.replace({
          ...this.$route,
          query,
        });
      } catch (error) {
        if (!error || error.name !== 'NavigationDuplicated') {
          throw error;
        }
      }
    },
  }
};
</script>
<style lang="scss" scoped>
  .page-info__active {
    display: flex;
    justify-content: space-between;
    align-items: center;

    input {
      max-width: 250px;
    }
  }

  .deals-pagination {
    text-align: center;
    margin: 10px;
  }

  .page-deals-no-deals {
    margin-top: 20px;
    color: $gray600;
  }
</style>
