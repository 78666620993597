<template lang="pug">
  .tabs-content.tabs-purse.wrapper_gray-border.pt-3
    .tabs
      input#tab1(:disabled="isIncomingLoading" :checked="currentTab === 'outgoing'").tab1(type='radio', name='tabs')
      label(
        v-loading="isOutgoingLoading",
        for='tab1',
        :class="{'tab-disabled': isIncomingLoading}"
        title='Просмотр активных претензий',
        @click="changeTab('outgoing')",
      ) Исходящие претензии

      input#tab2(:disabled="isOutgoingLoading" :checked="currentTab === 'incoming'").tab2(type='radio', name='tabs')
      label(
        v-loading="isIncomingLoading",
        for='tab2',
        :class="{'tab-disabled': isOutgoingLoading}"
        title='Просмотр завершенных претензий',
        @click="changeTab('incoming')"
      ) Входящие претензии
      label(title='Поиск сделки по ID', @click="open") &#128269
      el-dialog.search-claimsID(:visible="isShowClaims"  :title="`Претензия №${claimData.id}`" width="350px")
        claim-modal-item(:claimData="claimData" @closeModal="isShowClaims = false")
        button.el-dialog__headerbtn(type="button" aria-label="Close" @click="isShowClaims = false")
          i.el-dialog__close.el-icon.el-icon-close

      ClaimsList(:currentTab="currentTab")

</template>

<script>
  import {mapActions, mapGetters} from 'vuex';
  import ClaimsList from './ClaimsList';
  import {GET_INCOMING_CLAIMS, GET_OUTGOING_CLAIMS, GET_CLAIM_DATA} from "@/store/actions/claims";
  import ClaimModalItem from "./ClaimModalItem";
  import Claim from "@/components/Claims/Claim";

  export default {
  name: 'ClaimsContainer',
  components: {
    Claim,
    ClaimsList,
    ClaimModalItem
  },
  data() {
    const { query } = this.$route;
    const isIncoming = Number(query.incoming)
      || query.incoming === null; // null if value is not represented

    return {
      claimData: {},
      currentTab: isIncoming ? 'incoming' : 'outgoing',
      isShowClaims: false,
    };
  },
  computed: {
    ...mapGetters([
      'loading',
    ]),
    isOutgoingLoading() {
      return this.loading(GET_OUTGOING_CLAIMS) === 'loading';
    },
    isIncomingLoading() {
      return this.loading(GET_INCOMING_CLAIMS) === 'loading';
    },
  },
  methods: {
    changeTab(tab) {
      if (!this.isOutgoingLoading && !this.isIncomingLoading) {
        this.currentTab = tab;
      }
    },
    open() {
      this.$prompt('Введите ID претензии, которую хотите просмотреть', 'Поиск претензии по ID  ', {
        confirmButtonText: 'Перейти к претензии',
        cancelButtonText: 'Отмена',
      }).then(claimID => {
        this.$store.dispatch(GET_CLAIM_DATA, {id: claimID.value}).then(res => {
          this.claimData = res.data;
          this.isShowClaims = true;
        }).catch(error => {
          this.$message({
            message: 'Претензия не найдена',
            type: 'error',
            showClose: true
          })
        });
      });
    }
  },
};
</script>

<style scoped>
  .tabs-content .tabs > section {
    display: block;
  }

</style>
